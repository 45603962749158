// eslint-disable-next-line import/prefer-default-export
export const endpoints = {
  userLoginUrl: '/api/v1/parents/login',
  listDevicesUrl: '/api/v2/devices',
  subscriptionUrl: '/api/v1/parents/subscriptions',
  subscriptionBuyUrl: '/piggybank/subscriptions',
  subscriptionUpgradeUrl: '/piggybank/subscriptions/upgrade',
  createPurchaseIdUrl: '/piggybank/purchases',
  checkTrialUrl: '/piggybank/purchases/trial',
  skipFreeTrial: '/piggybank/purchases/trial/skip-reason-list',
  userSkipTrial: '/piggybank/purchases/trial/user-skip-reason',
  paymentMethodsUrl: '/piggybank/payment_gateway/payment_methods',
  setupIntentUrl: '/piggybank/payment_gateway/setup_intents',
  plansUrl: 'piggybank/products/skus?country=US&product_ids=4',
  updateDevices: (deviceId: string) => {
    return `/api/v3/devices/${deviceId}`;
  },
  getTransactionsUrl: (page: number) => {
    // return `/piggybank/purchases/transactions?page=${page}&limit=10`;
    return `/piggybank/purchases/transactions?skip=${page}&limit=10`;
  },
  getAllDeviceBySpaceIdUrl: (spaceId: string) => {
    return `parents/spaces/${spaceId}/devices`;
  },
  getUser: 'parents/users',
  getAllSpaces: 'parents/spaces',
  requestAccountDelete: '/api/v1/parents/deactivate',
  deleteUser: '/api/v2/parents/deactivate'
};
