import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { Stripe } from '@stripe/stripe-js';
import Stripe3DS from './Stripe';
import { initializeStripe } from '../../configs/stripeConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Authenticate3DS(props: any) {
  const {
    paymentOptionSelected,
    clientSecret,
    polling,
    setShowStripePaymentFailedPopUp,
    setStripeErrorResponse,
    setIsApiLoading,
    setTriggerStripePayment
  } = props;

  const [stripe, setStripe] = useState<Stripe | null>(null);

  const initializeStripeInstance = async () => {
    const stripeInstance = await initializeStripe();
    setStripe(stripeInstance);
  };

  useEffect(() => {
    initializeStripeInstance();
  }, []);

  if (!stripe || !clientSecret) {
    return <div>Loading...</div>;
  }

  return (
    clientSecret && (
      <Elements stripe={stripe}>
        <Stripe3DS
          client_secret={clientSecret}
          paymentOptionSelected={paymentOptionSelected}
          polling={polling}
          setShowStripePaymentFailedPopUp={setShowStripePaymentFailedPopUp}
          setStripeErrorResponse={setStripeErrorResponse}
          setIsApiLoading={setIsApiLoading}
          setTriggerStripePayment={setTriggerStripePayment}
        />
      </Elements>
    )
  );
}

export default Authenticate3DS;
