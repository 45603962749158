import { Box, Button } from '@mui/material';
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { StripeElements, StripeError } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { GetPaymentMethods } from 'services/api/paymentsApi';

type IProps = {
  submitPurchaseRequest: (stripe_payment_method: string) => void;
  amount: number;
  isFreeTrial: boolean;
  isUpgrade: boolean;
  isCancellationAndRefundPolicyAccepted?: boolean;
};

export default function StripeForm(props: IProps) {
  const {
    submitPurchaseRequest,
    amount,
    isUpgrade,
    isFreeTrial,
    isCancellationAndRefundPolicyAccepted = true
  } = props;
  const stripe = useStripe();
  const elements: StripeElements | null = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [loading, setLoading] = useState(false);

  const handleError = (error: StripeError) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    if (!elements) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Confirm the SetupIntent using the details collected by the Payment Element
    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required'
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      console.log('setupIntent', setupIntent);
      const { payment_method } = setupIntent || {};
      if (typeof payment_method === 'string') {
        submitPurchaseRequest(payment_method);
      }
      // alert('payment success');
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  useEffect(() => {
    GetPaymentMethods()
      .then((res) => {
        console.log('payment_methods', res);
      })
      .catch((err) => console.log('err payment_methods', err));
  }, []);
  return (
    <Box
      sx={{
        my: 2
      }}
    >
      <form onSubmit={handleSubmit}>
        <AddressElement
          options={{
            mode: 'billing',
            autocomplete: { mode: 'disabled' },
            fields: {
              phone: 'auto'
            }
          }}
        />
        <PaymentElement />
        {amount < 0 ? (
          <Button
            sx={{ width: '100%', marginTop: '20px' }}
            variant="contained"
            type="submit"
            disabled={!stripe || loading}
          >
            Add Card
          </Button>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
              marginBottom: '20px'
            }}
          >
            <Button
              sx={{
                width: '100%',
                outline: 'none',
                textTransform: 'none',
                '&:focus': {
                  outline: 'none'
                }
              }}
              variant="contained"
              type="submit"
              style={{
                color: '#fff',
                height: '52px',
                width: '100%',
                maxWidth: '300px',
                borderRadius: '44px',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px'
              }}
              disabled={
                !stripe || loading || !isCancellationAndRefundPolicyAccepted
              }
            >
              {isFreeTrial && !isUpgrade && 'Start Free Trial'}
              {isFreeTrial && isUpgrade && 'Upgrade Free Trial'}
              {!isFreeTrial && `Pay $${amount && amount / 100}`}
            </Button>
          </div>
        )}

        {errorMessage && (
          <Box
            sx={{
              mt: 2,
              color: 'error.main',
              textAlign: 'center'
            }}
          >
            {errorMessage}
          </Box>
        )}
      </form>
    </Box>
  );
}
