import { Button, Typography } from '@mui/material';
import PaymentSuccess from 'assets/payment-sucess.png';
import PopUp from 'components/PopUp';
import { TPartnerConfigurationDetails } from 'partner_configuration';
import styled from 'styled-components';
import { Flex, Image } from 'styles';
import { PopupTypes } from 'typings/popUpTypes';

const Info = styled.div`
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.22px;
  margin-bottom: 10px;
  align-self: center;
  text-align: center;
`;

const Heading = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const Content = styled.div`
  color: #484c4f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
`;

const ContactInfo = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
  text-decoration-line: underline;
`;

function PaymentSuccessPopup({
  isFreeTrial,
  isUpgrade,
  errorMessage,
  toggleModal,
  isOpen,
  config
}: {
  isFreeTrial: boolean;
  isUpgrade: boolean;
  errorMessage?: string;
  toggleModal: () => void;
  isOpen: boolean;
  config: TPartnerConfigurationDetails;
}) {
  return (
    <PopUp isOpen={isOpen} type={PopupTypes.TYPE2}>
      <Flex align="center" direction="column">
        <Image src={PaymentSuccess} width="60" height="60" />
        <Flex align="center" mbottom="24">
          <Flex direction="column" align="center" mbottom="16">
            <Info>Payment Successful</Info>

            {isFreeTrial && !isUpgrade && (
              <Typography fontSize={16} fontWeight={400} textAlign="center">
                Your free trial has been activated!
              </Typography>
            )}
            {isFreeTrial && isUpgrade && (
              <Typography fontSize={16} fontWeight={400} textAlign="center">
                Your free trial upgrade has been activated!
              </Typography>
            )}
            {!isFreeTrial && !isUpgrade && (
              <Typography fontSize={16} fontWeight={400} textAlign="center">
                Your subscription has been activated!
              </Typography>
            )}
            {!isFreeTrial && isUpgrade && (
              <Typography fontSize={16} fontWeight={400} textAlign="center">
                Your Upgraded subscription has been activated!
              </Typography>
            )}

            {errorMessage !== '' && (
              <Flex direction="column" align="baseline">
                {/* <Image src={AddCameraFail} width="62" height="62" /> */}
                <Heading>
                  Sorry! We were unable add the device your subscription plan
                </Heading>
                <Content>Please contact customer service via:</Content>
                <Flex align="center">
                  <Content>Email: </Content>
                  <ContactInfo>{config.email}</ContactInfo>
                </Flex>

                <Flex align="center">
                  <Content>Phone number: </Content>
                  <ContactInfo>{config.phone}</ContactInfo>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex justify="center">
        <Button variant="outlined" onClick={toggleModal}>
          Go To Subscription
        </Button>
      </Flex>
    </PopUp>
  );
}

export default PaymentSuccessPopup;
