/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, useLocation } from 'react-router-dom';

const redirectionURL = '/setup-init';

function AuthorizeDevice() {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const deviceId = query.get('device_id') || '';

  // useEffect(() => {
  //   navigate(`${redirectionURL}?device_id=${deviceId}&pairingFlow=true`);
  // }, []);

  // return null;
  return (
    <Navigate to={`${redirectionURL}?device_id=${deviceId}&pairingFlow=true`} />
  );
}
export default AuthorizeDevice;
