import { Button, Typography } from '@mui/material';
import PaymentFailure from 'assets/payment-fail.png';
import PopUp from 'components/PopUp';
import styled from 'styled-components';
import { Flex, Image } from 'styles';
import { PopupTypes } from 'typings/popUpTypes';

const Info = styled.div`
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.22px;
  margin-bottom: 10px;
  align-self: center;
`;

function PaymentFailurePopup({
  purchaseErrorMessage,
  handleResetLoading,
  isOpen
}: {
  purchaseErrorMessage: string;
  handleResetLoading: () => void;
  isOpen: boolean;
}) {
  return (
    <PopUp isOpen={isOpen} type={PopupTypes.TYPE2}>
      <Flex align="center" direction="column">
        <Image src={PaymentFailure} width="60" height="60" />
        <Flex align="center" mbottom="24">
          <Flex direction="column" align="center" mleft="10" mbottom="10">
            <Info>Payment Declined</Info>
            <Typography fontSize={16} fontWeight={400} textAlign="center">
              {purchaseErrorMessage ||
                'The credit card information was declined'}
            </Typography>
          </Flex>
        </Flex>
      </Flex>
      <Flex justify="center">
        <Button variant="outlined" onClick={() => handleResetLoading()}>
          Okay
        </Button>
      </Flex>
    </PopUp>
  );
}

export default PaymentFailurePopup;
