import { ThemeProvider } from '@mui/material';
import axios from 'axios';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import urls from 'configs/urls';
import { ConfigContext } from 'context';
import { getPartnerConfiguration, getPartnerName } from 'partner_configuration';
import CacheBuster from 'react-cache-buster';
import { Route, Routes } from 'react-router-dom';
import AppThemeProvider from 'themes/AppThemeProvider';
import AccountDeletionRequest from 'views/AccountDeletion/request';
import VerifyDeleteRequest from 'views/AccountDeletion/verifyRequest';
import { ActiveDevices } from 'views/ActiveDevices/ActiveDevices';
import AuthorizeDevice from 'views/AuthorizeDevice/AuthorizeDevice';
import AuthorizeFromApp from 'views/AuthorizeFromApp';
import AutoLogin from 'views/AutoLogin/AutoLogin';
import { Billings } from 'views/Billings/Billings';
import Bridge from 'views/Bridge';
import BuySubscription from 'views/BuySubscription/BuySubscription';
import CameraSelection from 'views/CameraSelection/CameraSelection';
import CancellationRefundPolicy from 'views/CancellationRefundPolicy/CancellationRefundPolicy';
import Features from 'views/Features/Features';
import NotFound from 'views/NotFound/NotFound';
import PaymentSuccess from 'views/PaymentSuccess/PaymentSuccess';
import PlanList from 'views/PlanList';
import SelectDevice from 'views/SelectDevice';
import SetupComplete from 'views/SetupComplete/SetupComplete';
import SetupInit from 'views/SetupInit';
import SkippingTrial from 'views/SkippingTrial/SkippingTrial';
import SubscriptionFunctions from 'views/SubscriptionFunctions/subscriptionFunctions';
import PaymentOptions from 'views/SubscriptionInfo/componenets/PaymentOptions';
import { SubscriptionDetails } from 'views/SubscriptionInfo/SubscriptionDetails';
import SubscriptionSummary from 'views/SubscriptionSummary/SubscriptionSummary';
import { UserInfo } from 'views/UserInfo/UserInfo';
import MissedFeatures from 'views/WhySubscribe/MissedFeatures';
import WhySubscribe from 'views/WhySubscribe/WhySubscribe';
import appPackageInfo from '../package.json';
import './App.css';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import LoginScreen from './views/LoginScreen/LoginScreen';

function App() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const url: any = window.location.origin;
  console.log('url::', url);
  const partnerName = getPartnerName(url);
  console.log(':: partnerName', partnerName);
  axios.defaults.baseURL = urls.baseUrl;
  axios.defaults.headers.common['Partner-ID'] = partnerName;
  axios.defaults.headers.common['Client-ID'] = 'web';
  // const dispatch = useAppDispatch();
  // const { isAuthenticated } = useAppSelector((state) => state.user);
  // const location = useLocation();
  const config = getPartnerConfiguration(url);
  const theme = AppThemeProvider(partnerName);

  // useEffect(() => {
  //   if (location.pathname.includes('authorize_device')) {
  //     return;
  //   }
  //   if (isAuthenticated) {
  //     dispatch(fetchUser());
  //   } else {
  //     LogoutUser();
  //   }
  // }, []);

  console.log('environment', process.env.NODE_ENV);

  return (
    <CacheBuster
      currentVersion={appPackageInfo.version}
      isEnabled={process.env.NODE_ENV === 'production'}
      isVerboseMode
      loadingComponent={<div>Loading...</div>}
    >
      <div className="App" style={{ height: '100vh' }}>
        <ErrorBoundary>
          <ConfigContext.Provider value={config}>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route path="login" Component={LoginScreen} />
                <Route path="auto-login" Component={AutoLogin} />
                <Route path="authorizeDevice" Component={AuthorizeDevice} />
                <Route path="authorizeFromApp" Component={AuthorizeFromApp} />
                <Route
                  path="requestDeleteServices"
                  Component={AccountDeletionRequest}
                />
                <Route
                  path="verifyDeleteServices"
                  Component={VerifyDeleteRequest}
                />
                <Route path="bridge" Component={Bridge} />
                <Route element={<PrivateRoute />}>
                  <Route path="/" Component={ActiveDevices} />
                  <Route path="/ActiveDevices" Component={ActiveDevices} />
                  <Route path="/MyAccount" Component={UserInfo} />
                  <Route
                    path="/MySubscription"
                    Component={SubscriptionDetails}
                  />
                  <Route path="/Billings" Component={Billings} />
                  <Route path="/buySubscription" Component={BuySubscription} />
                  <Route path="/planlist" Component={PlanList} />
                  <Route
                    path="/payment-selections"
                    Component={PaymentOptions}
                  />
                  <Route path="/payment-success" Component={PaymentSuccess} />
                  <Route path="/setup-init" Component={SetupInit} />
                  <Route path="/setup-complete" Component={SetupComplete} />
                  <Route path="/selectDevice" Component={SelectDevice} />
                  <Route path="/promotion" element={<Features />} />
                  <Route
                    path="/subscription-functions"
                    element={<SubscriptionFunctions />}
                  />
                  <Route path="/why-subscribe" element={<WhySubscribe />} />
                  <Route path="/missed-features" element={<MissedFeatures />} />
                  <Route path="/skip-trial" element={<SkippingTrial />} />
                  <Route
                    path="/camera-selection"
                    element={<CameraSelection />}
                  />
                  <Route
                    path="/subscription-summary"
                    element={<SubscriptionSummary />}
                  />
                  <Route
                    path="/cancellationRefundPolicy"
                    Component={CancellationRefundPolicy}
                  />
                  <Route path="*" Component={NotFound} />
                </Route>
                <Route path="*" Component={LoginScreen} />
              </Routes>
            </ThemeProvider>
          </ConfigContext.Provider>
        </ErrorBoundary>
      </div>
    </CacheBuster>
  );
}

export default App;
